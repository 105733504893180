
.propos{
    width: 100%;
  }
  
  .propos .container{
    width: 100%;
    margin: 3rem auto;
    padding: 0 0;
  }
  
  .propos h2{
    font-size: 2.2rem;
    text-align: center;
  }
  
  .propos .container .content{
    margin:7px 0 0 0;
  }
  
  .propos .card{
    border: 1px solid rgb(12, 214, 53);
    border-radius: 8px;
    box-shadow: 0 3px 10px rgb(0,0,0, .2);
    padding: 5px;
    box-sizing: border-box;
  }
  
  .propos .card:hover{
    cursor: auto;
    box-shadow: 10px 10px 10px rgb(0,0,0, .2)
  }
  .propos .card p{
    font-size: 18px;
  }
.propos .card span{
    font-size: 1.1rem;
    font-weight: 700;
  }
  .propos .video{
    display: flex;
    justify-content: center;
  }
  @media screen and (max-width:1000px) {
    .about .card p{
      font-size: 15px;
    }
    .propos video {
      width: 100%;
      height: auto;
  }
  }
  