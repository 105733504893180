#contact {
  display: flex;
 position: relative;
  width: 100%;
  background-color: #4E7E4E;
 background-size: 100%;
}
.contact .container{
  margin: 0.5rem auto;
  padding: 0rem auto;
}
.contact .col-sm-8 > p{
  color:var(--main-txt-color);
  font-size: 17px;
}
.contact .col-sm-4 > p{
  color:var(--main-txt-color);
  font-size: 20px;
}

.contact h1,h2{
  color:var(--main-txt-color);
  font-size: 1.5rem;
  text-align: left;
}
#LinkedIN:hover{
color: rgb(20, 80, 15);
}
#links{
  font-size: 30px;
}
section > p {
  color: var(--main-txt-color);
}









