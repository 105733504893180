
.about{
    width: 100%;
  }
  
  .about .container{
    width: 100%;
    margin: 3rem auto;
    padding: 0 0;
  }
  
  .about h2{
    font-size: 2.2rem;
    text-align: center;
  }
  
  .about .container .content{
    margin:0;
  }
  
  .about .card{
    border: 1px solid rgb(12, 214, 53);
    border-radius: 8px;
    box-shadow: 0 3px 10px rgb(0,0,0, .2);
    padding: 5px;
    box-sizing: border-box;
  }
  
  .about .card:hover{
    cursor: auto;
    box-shadow: 10px 10px 10px rgb(0,0,0, .2)

  }

  .about .card p{
    font-size: 18px;
  }
.about .card span{
    font-size: 1.1rem;
    font-weight: 700;
  }
  .about .video{
    display: flex;
    justify-content: center;
  }
@media screen and (max-width:1000px) {
  .about .card p{
    font-size: 15px;
  }
  .about video{
    width: 100%;
    height: auto;
  }
}


  