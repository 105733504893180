
.d-block{
  height: 100vh;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  max-width: 100vw;
  object-fit: cover;
  }
.drapeau{
  width:40px;
  height:26px;
}
.slide #texte{
  position: absolute;
  bottom: 100px;
}
.slide #texte > span{
  font-size: 43px;
  line-height: 30px;
  font-weight: 500;
  color:#eef5ee;
  border-color: #4E7E4E;
  border: 2px solid  #4E7E4E;
  background-color: #4E7E4E;
  border-radius: 5px;
  text-transform: uppercase;
  
}
.slide #texte > p{
  font-size: 27px;
  animation-name: animate;
  animation-duration: 5s;
  line-height: 34px;
}

#logo{
  width: 200px;
  margin-left: 20px;
}

.navigation{
  position: relative;
  display: flex;
  justify-content: space-between ;
  width: 100%;
  box-sizing: border-box;
  align-items: center;
  background-color: rgb(253, 254, 255);
}
.navigation .nav-menu{
  display: flex;
}
.navigation .nav-menu ul{
  position: relative;
  display: flex;
  align-items: center;
  margin: 0 0 0 0;
}
.navigation .nav-menu ul li{
  margin: 0 25px;
  font-size: 20px;
  color: black;
  cursor: pointer;
  font-weight: 400;
 
}
.navigation .nav-menu ul li:hover{
  color:rgb(50, 105, 12) ;
}
@keyframes animate {
  from{
    opacity: 0;
  }
  to{
    opacity: 1;
  }
}