

  .naviguer #texte > span{
    font-size: 43px;
    line-height: 30px;
    color:#08bb08;
    max-width: 200px;
    font-weight: 500;
   
  }
  .naviguer #texte > p{
    font-size: 27px;
    line-height: 30px;
  animation-name: animate;
  animation-duration: 5s;
  }
  @keyframes animate {
    from{
      opacity: 0;
    }
    to{
      opacity: 1;
    }
  }
  .naviguer nav img{
    width: 300px;
  }
  .naviguer{
    position: relative;
    display: flex;
    justify-content: space-between ;
    width: 100%;
    box-sizing: border-box;
    align-items: center;
    background-color: rgb(253, 254, 255);
  
  }
  .naviguer .nav-links{
    display: flex;
  }
  .naviguer .nav-links ul{
    position: relative;
    display: flex;
    align-items: center;
    margin: 0 0 0 0;
  }
  .naviguer .nav-links ul li{
    margin: 0 25px;
    font-size: 20px;
    color:black; 
    cursor: pointer;
    font-weight: 400;
  }
  .naviguer .nav-links ul li:hover{
    color:rgb(50, 105, 12);
  }
  .naviguer .fas{
    cursor: pointer;
    display: none;
    position: relative;
    top: 19px;
    right: 20px;
    font-size: 2em;
  }
  .naviguer .bouton{
    cursor: pointer;
    display: none;
    position: relative;
    top: 19px;
    right: 20px;
    font-size: 2em;
  }
  
  
  