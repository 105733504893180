body {
  margin: 0;
}
*{
  padding: 0;
  margin: 0;
  list-style: none;
  box-sizing: border-box;
  font-family: var(--main-font-family);
}

:root{
 --main-txt-color:whitesmoke;
 --main-font-family: @font-face 
 {
  font-family: 'Montserrat';
  font-style:normal;
  font-weight: 300;
  src:local('Montserrat'),local('Montserrat-Light'),url('./components/polices/static/Montserrat-Light.ttf') format('woff2');
};
}
@media screen and (max-width:1017px) {
  .slide #texte > span{
    font-size: 33px;
   }
}
@media  screen and (max-width: 782px) {
    .slide #texte > span{
     font-size: 29px;
    }
    .slide #texte > p{
      font-size: 20px;
    }
   
 }
 @media screen and (max-width:703px) {
  #logo{
    width: 150px;
    margin: 0 0 0 5px;
  }
 }
 @media screen and (max-width:667px) {
  #logo{
    width: 100px;
    margin: 0 0 0 5px;
  }
  .navigation{
    height: 75px;
    margin: 0;
    width: 100%;
  }
  .naviguer{
    height: 75px;
    margin: 0;
    width: 100%;
  }
  .navigation .bouton{
   top: 3px;
   margin: 0;
  }
  .naviguer .bouton{
    top: 3px;
   margin: 0;
  }
  .slide #texte > span{
  font-size: 20px;   
  }
  .slide #texte > p{
    font-size: 15px;
    line-height: 17px;   
    }
    .about h2{
      font-size: 2rem;
      text-align: center;
    }
 }
 @media screen and (max-width:588px) {
  .naviguer .nav-links ul li{
    margin: 0 10px;
    font-size: 15px;
  }
 }
 @media screen and (max-width:552px) {
  .navigation .nav-menu ul li{
    margin: 0 15px;
    font-size: 15px;
   
  }
}
 @media screen and (max-width:428px) {
  .navigation .nav-menu ul li{
    margin: 0 07px;
    font-size: 15px;
  }
  #logo{
    width: 80px;
  }
.drapeau{
  width: 30px;
  height: 20px;
}
.navigation .nav-menu ul{
  right: 15px;
}
}
@media screen and (max-width:386px) {
  .naviguer .nav-links ul li{
    margin: 0 05px;
    font-size: 15px;
  }
}
@media screen and (max-width:346px) {
  .naviguer .nav-links ul li{
    margin: 0 3px;
  }
  .naviguer .nav-links ul{
    right: 17px;
  }
}
@media screen and (max-width:334px) {
  .navigation .nav-menu ul li{
    margin: 0 05px;
    font-size: 15px;
  }
  .naviguer .nav-links ul li{
    font-size: 12px;
  }
}
@media screen and (max-width:318px) {
  
  .navigation .nav-menu ul{
    right: 20px;
  }
}
@media screen and (max-width:302px) {
  .navigation .nav-menu ul li{
    margin: 0 03px;
    font-size: 13px;
  }
  .drapeau{
    width:20px;
    height:10px;
  }
  .navigation .nav-menu ul{
    right: 23px;
  }
  
}
@media  screen and (max-width:289px) {
  .naviguer .nav-links ul li{
    margin: 0 2px;
    font-size: 13px;
  }
  #logo{
    width: 70px;
  }
}
